import React, { useEffect, useRef } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from '../../pages/Table/sass/TableScreener.module.scss';
import RemoveSubItemModal from './RemoveSubItemModal';
import { MODAL_TITLES } from '../../constants/screener';
import useNewScreen from '../../utils/hooks/useNewScreen';
import { findItemColumnSet } from '../../utils/helperNewScreenModal';
import { setVisibleColumnsScreenAction } from '../../store/screener/actions';
import CreateScreenModalHandler from './CreateScreenModalHandler';
import DuplicateModalHandler from './DuplicateModalHandler';
import useNewColumnSet from '../Columns/hooks/useNewColumnSet';
import CommonModal from '../WatchListModals/CommonModal';
import { setModalType } from '../../store/watchlist/actions';
import { setCreatedFolder } from '../../store/newScreen/actions';
import { useDashboardItems } from '../../context/DasboardItemsContext/DashboardItemsProvider';

const NewScreenModals = ({
  openModalHandler,
  openModal,
  currentScreensItem,
  currentScreensSubItem,
  currentItemHandler,
  singleItemStatus,
  currentItemSelected,
  selectedScreenId,
  hoveredPresetItem,
  modalType,
  setActiveModalWrapper,
  closeModalControlled,
  controledAction,
  isDashboard,
  controlledEditName,
  resetSearchHandler,
}) => {
  const dispatch = useDispatch();
  const currentRef = useRef(null);

  const closeModalHandler = () => {
    setActiveModalWrapper(false);
    openModalHandler('');
    currentItemHandler(-1);
    if (modalType !== 'addSymbol' && modalType !== 'deleteSymbols'
      && modalType !== 'signOut') dispatch(setModalType(''));
    dispatch(setCreatedFolder(null));
    closeModalControlled();
  };

  const { moveToFolder, createScreen, createPresets } = useNewScreen(currentItemSelected, closeModalHandler);
  const { createColumnsSet } = useNewColumnSet(closeModalHandler);
  const {
    screeners,
    itemsColumnSets,
    presetsFolders,
    columnsFolders,
    presets,
    itemsNewScreen
  } = useDashboardItems();

  const { removeSubItemFromFolder } = useNewScreen(currentItemSelected);
  const groupFilters = useSelector((state) => state.newScreenState.requestStateFilters, shallowEqual);

  const currentItem = screeners.find((item) => item?.id === currentScreensItem);
  const currentPresetItem = presets.find((item) => item?.id === hoveredPresetItem);
  const currentFolderPresetItem = presetsFolders.find((item) => item?.id === hoveredPresetItem);
  const currentSubItem = currentItem && currentItem?.items?.length
    ? currentItem.items[currentScreensSubItem] : '';

  // const hoveredFolderColumnSetsId = useSelector(
  //   (state) => state.newScreenState.hoveredFolderColumnSetsId, shallowEqual
  // );
  const hoveredColumnSetsId = useSelector(
    (state) => state.newScreenState.hoveredColumnSetsId, shallowEqual
  );

  const selectedColumns = useSelector((state) => state.screenerState.selectedColumns, shallowEqual);

  const currentColumnSetItem = findItemColumnSet(itemsColumnSets, hoveredColumnSetsId);

  useEffect(() => {
    dispatch(setVisibleColumnsScreenAction(false));
  }, []);

  useEffect(() => {
    if (currentRef.current) {
      setActiveModalWrapper(true);
    } else {
      setActiveModalWrapper(false);
    }
  }, [currentRef]);

  return (
    <div
      aria-hidden="true"
      className={styles.modalWrapper}
      ref={currentRef}
    >
      <ClickAwayListener
        onClickAway={closeModalHandler}
        touchEvent="onTouchEnd"
      >
        <div className={styles.createScreenWrapper}>
          <CreateScreenModalHandler
            openModal={`${openModal}`}
            closeModalHandler={closeModalHandler}
            selectedScreenId={selectedScreenId}
            itemsNewScreen={itemsNewScreen}
            foldersPresets={presetsFolders}
            groupFilters={groupFilters}
            selectedColumns={selectedColumns}
            columnSetFolder={columnsFolders}
            moveToFolder={moveToFolder}
            createScreen={createScreen}
            createPresets={createPresets}
            createColumnsSet={createColumnsSet}
            resetSearchHandler={resetSearchHandler}
            currentItemSelected={currentItemSelected}
          />

          <DuplicateModalHandler
            openModal={openModal}
            closeModalHandler={closeModalHandler}
            duplicatePreset={() => {}}
            currentPresetItem={currentSubItem || currentItem || currentPresetItem}
            itemsColumnSets={itemsColumnSets}
            currentColumnSetItem={currentColumnSetItem}
          />

          {(modalType === MODAL_TITLES.CREATE || MODAL_TITLES.CREATE_DATA_PANEL
              || MODAL_TITLES.CREATE_DATA_PANEL_PRESET)
            && !isDashboard && (
              <CreateScreenModalHandler
                openModal={`${modalType}`}
                closeModalHandler={closeModalHandler}
                selectedScreenId={selectedScreenId}
                itemsNewScreen={itemsNewScreen}
                foldersPresets={presetsFolders}
                groupFilters={groupFilters}
                selectedColumns={selectedColumns}
                columnSetFolder={columnsFolders}
                moveToFolder={moveToFolder}
                createScreen={createScreen}
                createPresets={createPresets}
                createColumnsSet={createColumnsSet}
                resetSearchHandler={resetSearchHandler}
                currentItemSelected={currentItemSelected}
              />
          )}
          {(modalType === MODAL_TITLES.CREATE_DATA_PANEL
              || MODAL_TITLES.CREATE_DATA_PANEL_PRESET)
            && isDashboard && (
              <CreateScreenModalHandler
                openModal={`${modalType}`}
                closeModalHandler={closeModalHandler}
                selectedScreenId={selectedScreenId}
                itemsNewScreen={itemsNewScreen}
                foldersPresets={presetsFolders}
                groupFilters={groupFilters}
                selectedColumns={selectedColumns}
                columnSetFolder={columnsFolders}
                moveToFolder={moveToFolder}
                createScreen={createScreen}
                createPresets={createPresets}
                createColumnsSet={createColumnsSet}
                resetSearchHandler={resetSearchHandler}
                currentItemSelected={currentItemSelected}
              />
          )}
          {(modalType === MODAL_TITLES.CREATE_DASHBOARD_PRESET
              || modalType === MODAL_TITLES.CREATE_DASHBOARD) && (
              <CreateScreenModalHandler
                openModal={`${modalType}`}
                closeModalHandler={closeModalControlled}
                selectedScreenId={selectedScreenId}
                itemsNewScreen={itemsNewScreen}
                foldersPresets={presetsFolders}
                groupFilters={groupFilters}
                selectedColumns={selectedColumns}
                columnSetFolder={columnsFolders}
                moveToFolder={moveToFolder}
                createScreen={createScreen}
                createPresets={createPresets}
                createColumnsSet={createColumnsSet}
                resetSearchHandler={resetSearchHandler}
                currentItemSelected={currentItemSelected}
              />
          )}

          {(modalType === MODAL_TITLES.RENAME || modalType === MODAL_TITLES.DELETE) && isDashboard && (
          <CommonModal
            controlledEditName={controlledEditName}
            editDasboard={controledAction}
            closeDashboardModal={closeModalControlled}
            type={modalType}
            isDashboard={isDashboard}
          />
          )}

          {openModal === MODAL_TITLES.REMOVE && (
          <RemoveSubItemModal
            removeSubItemFromFolder={removeSubItemFromFolder}
            currentSubItem={currentSubItem}
            setOpenModal={closeModalHandler}
            singleItemStatus={singleItemStatus}
            currentPresetItem={currentPresetItem}
            currentItem={currentItem || currentPresetItem || currentFolderPresetItem}
          />
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

NewScreenModals.propTypes = {
  singleItemStatus: PropTypes.bool,
  currentItemHandler: PropTypes.func,
  selectedScreenId: PropTypes.number,
  hoveredPresetItem: PropTypes.number,
  currentScreensItem: PropTypes.number,
  currentItemSelected: PropTypes.func,
  openModal: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  currentScreensSubItem: PropTypes.number,
  openModalHandler: PropTypes.func.isRequired,
  setActiveModalWrapper: PropTypes.func,
  isDashboard: PropTypes.bool,
  closeModalControlled: PropTypes.func,
  controledAction: PropTypes.func,
  controlledEditName: PropTypes.string,
  resetSearchHandler: PropTypes.func,
};

NewScreenModals.defaultProps = {
  selectedScreenId: -1,
  hoveredPresetItem: -1,
  currentScreensItem: -1,
  singleItemStatus: false,
  currentScreensSubItem: -1,
  currentItemHandler: () => undefined,
  currentItemSelected: () => undefined,
  setActiveModalWrapper: () => undefined,
  isDashboard: false,
  closeModalControlled: () => undefined,
  controledAction: () => undefined,
  controlledEditName: '',
  resetSearchHandler: () => undefined,
};

export default React.memo(NewScreenModals);
