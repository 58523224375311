import React, {
  useState, useEffect, useMemo, SyntheticEvent
} from 'react';

import { refreshStateCheck, convertDateString } from '../../utils/helperFilter';
import constants from '../../constants/filters';
import FilterLayout from '../../layout/FilterLayout/FilterLayout';
import ContainerPicker from './ContainerPicker';
import numberConstants from '../../constants/numberConstants';
import { TCommonItem } from '../FilterScreen/Types';

type TRangeState = {
  after: boolean;
  before: boolean;
  range: boolean;
};

interface IDatePicker {
  placeholder: string;
  title: string;
  cancelText: string;
  applyText: string;
  placeholderPeriod: string;
  favoriteActive: boolean;
  addFavorite: () => void;
  stateFiltersHandler: (array: (number | number[])[]) => void;
  columnId: number;
  disabled: boolean;
  disabledHeart: boolean;
  rangeButtonType: string;
  categoryName: string;
  searchFilter: string | number;
  filter: TCommonItem;
  hideLayout: boolean;
  description: string;
}

const DatePicker = ({
  placeholder,
  title,
  cancelText,
  applyText,
  placeholderPeriod,
  favoriteActive,
  addFavorite,
  stateFiltersHandler,
  columnId,
  disabled,
  disabledHeart,
  rangeButtonType,
  categoryName,
  searchFilter,
  filter,
  hideLayout,
  description,
}: IDatePicker): React.ReactElement => {
  const [startSingleDate, setStartSingleDate] = useState<Date | null>(null);
  const [openCalendar, setOpenCalendar] = useState<boolean>(true);
  const [apply, setApply] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<Date[] | null[]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [activeTab, setActiveTabs] = useState<TRangeState>({
    after: true,
    before: false,
    range: false
  });
  const [correctPeriod, setCorrectPeriod] = useState(false);

  useEffect(() => () => setOpenCalendar(false), []);
  useEffect(() => {
    if (activeTab.range) {
      setStartSingleDate(null);
    }
  }, [activeTab]);

  const refreshDate = (date: number): void => {
    if ((date * constants.correctData) > constants.startDateCount) {
      setStartSingleDate(convertDateString(date * constants.correctData));
    } else {
      setStartSingleDate(null);
    }
  };

  const refreshPeriodDate = (startDatePeriod: number, endDatePeriod: number): void => {
    if ((startDatePeriod * constants.correctData) > constants.startDateCount
      && (endDatePeriod * constants.correctData) > constants.startDateCount) {
      setDateRange([
        convertDateString(startDatePeriod * constants.correctData),
        convertDateString(endDatePeriod * constants.correctData)
      ]);
    } else {
      setDateRange([null, null]);
    }
  };

  useEffect(() => {
    const item = document.getElementById('General');
    const itemEarn = document.getElementById('Earnings');
    const itemRatios = document.getElementById('Fundamentals');

    const closeCalendar = () => {
      setOpenCalendar(false);
    };

    if (item) {
      item?.addEventListener('scroll', closeCalendar);
      itemEarn?.addEventListener('scroll', closeCalendar);
      itemRatios?.addEventListener('scroll', closeCalendar);
    }

    return () => {
      item?.removeEventListener('scroll', closeCalendar);
      itemEarn?.removeEventListener('scroll', closeCalendar);
      itemRatios?.removeEventListener('scroll', closeCalendar);
    };
  }, []);

  const refreshStateDate = (): void => {
    const itemDate = { ...filter };
    const newStateActive = refreshStateCheck(activeTab) as TRangeState;
    const currentStartDate = itemDate?.fVal[0]?.value as number;
    const currentEndDate = itemDate?.fVal[1]?.value as number;

    if (!currentEndDate) {
      newStateActive.after = true;
      refreshDate(currentStartDate);
    } else if (!currentStartDate) {
      newStateActive.before = true;
      refreshDate(currentEndDate);
    } else if (currentStartDate > numberConstants.dateStarter
      && currentEndDate > numberConstants.zeroCount) {
      newStateActive.range = true;
      setCorrectPeriod(true);
      refreshPeriodDate(currentStartDate, currentEndDate);
    }
    setActiveTabs(newStateActive);
  };

  useEffect(() => {
    if (Object.keys(filter).length > numberConstants.zeroCount) {
      refreshStateDate();
    }
  }, [filter, searchFilter]);

  const tabsHandler = (item: string): void => {
    const body = {
      after: false,
      before: false,
      range: false
    };
    body[item as keyof TRangeState] = true;
    setActiveTabs(body);
  };

  const onFieldChange = (index: number, date: Date): void => {
    const newPeriod = [...dateRange];
    newPeriod[index] = date;
    setDateRange(newPeriod as Date[] | null[]);
  };

  const closeCalendarHandler = (): void => {
    setOpenCalendar(false);
  };

  const handleJustOpenDatePicker = (): void => {
    if (!activeTab.after && !activeTab.before && !activeTab.range) {
      setActiveTabs({ ...activeTab, after: true });
    }
    setOpenCalendar(true);
  };

  const applySelectDate = (): void => {
    const currentDate = new Date().getTime() / constants.correctData;
    let startStateDate: number | null = null;
    let endStateDate: number | null = null;
    if (activeTab.before && startSingleDate !== null) {
      endStateDate = Date.parse(startSingleDate as unknown as string) / constants.correctData;
    } else if (activeTab.after && startSingleDate !== null) {
      startStateDate = Date.parse(startSingleDate as unknown as string) / constants.correctData;
    } else if (activeTab.before && startSingleDate === null) {
      endStateDate = Number(currentDate.toFixed());
    } else if (activeTab.after && startSingleDate === null) {
      startStateDate = Number(currentDate.toFixed());
    }
    stateFiltersHandler([columnId, 1, [startStateDate as number, endStateDate as number]]);
    setApply(true);
  };

  const applySelectRange = (event: SyntheticEvent, date: Date[]): void => {
    let startStateDate: number | null = null;
    let endStateDate: number | null = null;

    const [first, second] = date;
    startStateDate = first.setHours(0, 0, 0) / constants.correctData;
    endStateDate = second.setHours(23, 59, 59) / constants.correctData;
    stateFiltersHandler([columnId, 1, [startStateDate, endStateDate]]);
    setApply(true);
  };

  const cancelSelect = (): void => {
    setApply(true);
    setStartSingleDate(null);
    setDateRange([null, null]);
    refreshStateDate();
  };

  useEffect(() => {
    closeCalendarHandler();
    setApply(false);
  }, [apply]);

  return useMemo(() => (
    <>
      {!hideLayout ? (
        <FilterLayout
          title={title}
          addButtonHandler={addFavorite}
          favoriteActive={favoriteActive}
          id={columnId}
          columnId={columnId}
          disabled={disabled}
          disabledHeart={disabledHeart}
          description={description}
          category={categoryName}
          autoCloseCalendar={closeCalendarHandler}
          handlerCloseDropdown={closeCalendarHandler}
        >
          <ContainerPicker
            startDate={startDate as Date}
            endDate={endDate as Date}
            columnId={columnId}
            applyText={applyText}
            activeTab={activeTab}
            tabsHandler={tabsHandler}
            cancelText={cancelText}
            cancelSelect={cancelSelect}
            openCalendar={openCalendar}
            placeholder={placeholder}
            categoryName={categoryName}
            onFieldChange={onFieldChange}
            activeTabRange={activeTab?.range}
            applySelectDate={applySelectDate}
            startSingleDate={startSingleDate as Date}
            applySelectRange={applySelectRange}
            rangeButtonType={rangeButtonType}
            placeholderPeriod={placeholderPeriod}
            setStartSingleDate={setStartSingleDate}
            handleJustOpenDatePicker={handleJustOpenDatePicker}
            correctPeriod={correctPeriod}
            styleType=""
          />
        </FilterLayout>
      ) : (
        <ContainerPicker
          startDate={startDate as Date}
          endDate={endDate as Date}
          columnId={columnId}
          applyText={applyText}
          activeTab={activeTab}
          tabsHandler={tabsHandler}
          cancelText={cancelText}
          cancelSelect={cancelSelect}
          openCalendar={openCalendar}
          placeholder={placeholder}
          categoryName={categoryName}
          onFieldChange={onFieldChange}
          activeTabRange={activeTab?.range}
          applySelectDate={applySelectDate}
          startSingleDate={startSingleDate as Date}
          applySelectRange={applySelectRange}
          rangeButtonType={rangeButtonType}
          styleType={constants.withoutLayout}
          placeholderPeriod={placeholderPeriod}
          setStartSingleDate={setStartSingleDate}
          handleJustOpenDatePicker={handleJustOpenDatePicker}
        />
      ) }
    </>
  ), [
    startSingleDate,
    openCalendar,
    apply,
    placeholder,
    activeTab,
    dateRange,
    searchFilter,
    stateFiltersHandler
  ]);
};

export default DatePicker;
